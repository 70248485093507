import { render, staticRenderFns } from "./DriveSettings.vue?vue&type=template&id=c9583bac&scoped=true&"
import script from "./DriveSettings.vue?vue&type=script&lang=js&"
export * from "./DriveSettings.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9583bac",
  null
  
)

export default component.exports