<template>
  <div class="flex mx-auto my-auto mt-10">
    <div class="w-full">
      <svg
        class="animate-spin mx-auto opacity-50"
        v-if="!error && !selectDatabase"
        style="width: 48px; height: 48px"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          style="opacity: 0.25"
          cx="12"
          cy="12"
          r="10"
          stroke="#7f7f7f"
          stroke-width="4"
        ></circle>
        <path
          style="opacity: 0.75"
          fill="#7f7f7f"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      <div class="mt-5 text-grey3">
        {{ message }}
      </div>

      <div class="flex" v-if="error">
        <router-link :to="{ name: 'profile' }" class="mx-auto">
          <m-button
            class="self-end mb-3 mt-5"
            button-style="btn-tertiary"
            title="Back to profile settings"
            segmentName="Drive: back to profile settings"
            icon="⚙️"
          >
            Back to profile settings
          </m-button>
        </router-link>
      </div>
      <div class="flex" v-if="selectDatabase">
        <m-input
          class="lg:ml-1 pl-1 lg:pl-2 text-lg lg:text-base text-grey3 placeholder-grey3 font-medium"
          value=""
          placeholder="Search for Notion pages"
          v-model="searchTerm"
          title="Search"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { connectDrive } from '@/api/Cherry';
import { segmentTrackRaw } from '@/api/Segment';
import MButton from '@/components/UI/MButton';
import MInput from '@/components/UI/MInput';
export default {
  components: {
    MButton,
    MInput,
  },
  data() {
    return {
      message: 'Connecting to Drive...',
      error: false,
      selectDatabase: false,
      searchTerm: '',
    };
  },
  mounted() {
    const code = this.$route.query.code;
    if (!code) {
      const errorQuery = this.$route.query.error;
      this.showError(errorQuery);
    } else {
      this.connectWithCode(code);
    }
  },
  methods: {
    connectWithCode: function (code) {
      const payload = { code: code };

      connectDrive(payload)
        .then((r) => {
          // console.log(r)
          if (r.error) {
            this.showError(r.error);
          } else {
            this.$store.commit('setDriveIntegration', r);
            this.$router.replace({
              name: 'profile',
            });
          }
        })
        .catch((e) => {
          console.error(e);
          this.showError(e);

        });
    },
    showDatabaseSelect: function () {},
    showError: function (message) {
      if (message) {
        this.message = 'Error connecting to Drive: ' + message;
      } else {
        this.message = 'Invalid response from Drive';
      }
      this.error = true;
      segmentTrackRaw({
        event: 'Error',
        properties: { errorMsg: 'drive-connect', details: this.message },
      });
    },
  },
};
</script>
<style scoped>
body {
  margin: 0;
  height: 100%;
  background-color: #fafcff;
}
#loader .animate-spin {
  animation: spin 1s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
