<template>
  <div class="mx-auto my-auto mt-10">
  <!--
    <div class="flex mt-10">
      <m-button
        class="self-end"
        button-style="btn-primary"
        @click="exportAll"
        title="Export all notes to Drive"
        segmentName="Drive: export all notes"
        icon="✉️"
      >
        Export all notes to Google Drive
      </m-button>
    </div>
    -->
    <div class="flex mt-10">
      <router-link :to="{ name: 'profile' }" class="mx-auto">
        <m-button
          class="self-end"
          button-style="btn-primary"
          title="Save"
          segmentName="Notion: save page ID"
          icon="⚙️"
        >
          Back to profile settings
        </m-button>
      </router-link>
    </div>
    <div class="flex mt-5">
      <m-button
        class="mx-auto"
        @click="disconnectDrive"
        buttonStyle="btn-tertiary"
        segmentName="Disconnect Drive"
        :pending="driveButtonPending"
      >
        Remove Google Drive integration
      </m-button>
    </div>
    <div class="mt-5 text-alerterror text-center">{{ errorText }}</div>

    <!-- 
    <div class="mt-10 text-grey2 text-center">
      <a-link
        href="https://meetric.notion.site/TBD"
        >Need help with Google Drive integration?</a-link
      >
    </div>
    -->
  </div>
</template>
<script>
import MButton from '@/components/UI/MButton';
import { removeDrive, exportAllToDrive } from '@/api/Cherry';
export default {
  name: 'DriveSettingsPage',
  components: {
    MButton,
  },
  data() {
    return {
      pageSelected: '',
      buttonText: 'Save',
      errorText: '',
      driveButtonPending: false,
      placeholder: 'loading',
      savingChange: true,
    };
  },
  computed: {
    driveDetails() {
      return this.$store.getters['driveDetails'];
    },
  },
  mounted() {},
  methods: {
    disconnectDrive() {
      this.driveButtonPending = true;
      removeDrive().then(() => {
        this.$store.commit('setDriveIntegration', {});
        this.$router.push({
          name: 'profile',
        });
        this.driveButtonPending = false;
      });
    },
    exportAll() {
      exportAllToDrive().then(() => {
        console.log('Done');
      });
    },
  },
};
</script>
<style scoped></style>
